// ./src/pages/index.js
import React from "react"
import { Link } from "gatsby"
import Navbar from '../components/navbar'

export default () => (
  <div>
    <Navbar> </Navbar>
    <p>Welcome to pandacrylic.

    </p>
    <img height="200" width="auto" src="https://fesapusewebsite.blob.core.windows.net/fathom/promare-ticketing-opt-16602b73d6c359f25ffd1023da7cd3ca.jpg"/>
    <p> {''}</p>
    <Link to="/account">Go to your account. Don't have one? Click here to create one. </Link>
  </div>
)